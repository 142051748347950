import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

const firebaseConfig = {
    apiKey: "AIzaSyDMNNllzbfxRz3e-BpD4_tmBhDgMdYjeTI",
    authDomain: "mint-3509e.firebaseapp.com",
    databaseURL: "https://mint-3509e-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "mint-3509e",
    storageBucket: "mint-3509e.appspot.com",
    messagingSenderId: "185193169827",
    appId: "1:185193169827:web:c84e0e2441ca6d34299016"
};

// Initialize Firebase services
firebase.initializeApp(firebaseConfig)

const db = firebase.firestore()
const storage = firebase.storage()
const functions = firebase.functions()

export { db, storage, functions }