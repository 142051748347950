import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";
import NProgress from "nprogress";
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import store from "./store/store.js";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0;

        if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        }

        return goTo(scrollTo);
    },
    routes: [
        {
            path: "/",
            redirect: "home",
            component: () => import("@/layouts/full-layout/Layout"),
            children: [
                {
                    name: "Home",
                    path: "home",
                    component: () => import("@/views/Home")
                }
            ]
        },
        {
            path: "/manager",
            redirect: "/manager/projects",
            component: () => import("@/layouts/full-layout/Layout"),
            children: [
                {
                    name: "Availabilities",
                    path: "availabilities",
                    component: () => import("@/views/projects/Availabilities")
                },
                {
                    name: "ManagerTimebooks",
                    path: "timebooks",
                    component: () => import("@/views/projects/Timebooks")
                },
                {
                    name: "ManagerTimesheets",
                    path: "timesheets",
                    component: () => import("@/views/projects/Timesheets")
                }
            ]
        },
        {
            name: "Manager Projects",
            path: "/manager/projects",
            props: true,
            component: () => import("@/layouts/full-layout/Layout"),
            children: [
                {
                    path: ":type",
                    component: () => import("@/views/projects/Projects-simple")
                },
            ]
        },
        {
            path: "/direction",
            redirect: "/direction/projects",
            component: () => import("@/layouts/full-layout/Layout"),
            children: [
                {
                    name: "Direction Projects",
                    path: "projects",
                    props: true,
                    component: () => import("@/views/admin/Projects"),
                },
                {
                    name: "Incomplete Projects",
                    path: "incomplete",
                    component: () => import("@/views/admin/IncompleteProjects")
                },
                {
                    name: "Timebooks",
                    path: "invoices/timebooks",
                    component: () => import("@/views/admin/invoices/Timebooks")
                },
                {
                    name: "Support",
                    path: "invoices/support",
                    component: () => import("@/views/admin/invoices/Support")
                },
                {
                    name: "Timesheet",
                    path: "invoices/timesheet",
                    component: () => import("@/views/admin/invoices/Timesheet")
                },
                {
                    name: "Projects",
                    path: "invoices/projects",
                    component: () => import("@/views/admin/invoices/Projects")
                },
                {
                    name: "Hosting",
                    path: "invoices/hosting",
                    component: () => import("@/views/admin/invoices/Hosting")
                },
                {
                    name: "Updates",
                    path: "updates",
                    component: () => import("@/views/admin/Updates")
                },
                {
                    name: "Monitoring",
                    path: "monitoring",
                    component: () => import("@/views/admin/Monitoring")
                }
            ]
        },
        {
            path: "/collaborater",
            redirect: "/collaborater/planning",
            component: () => import("@/layouts/full-layout/Layout"),
            children: [
                {
                    name: "Planning",
                    path: "planning",
                    component: () => import("@/views/projects/Planning")
                }
            ]
        },
        {
            path: '/development',
            redirect: "development/snippets",
            component: () => import("@/layouts/full-layout/Layout"),
            children: [
                {
                    name: "Snippets",
                    path: "snippets",
                    component: () => import('@/views/shared/Snippets')
                }
            ]
        },
        {
            path: "/management",
            redirect: "/home",
            component: () => import("@/layouts/full-layout/Layout"),
            children: [
                {
                    name: "Processus",
                    path: "processus",
                    component: () => import("@/views/quality/Processus")
                },
                {
                    name: "Process",
                    path: "processus/:processId",
                    props: true,
                    component: () => import("@/views/quality/Process/Process")
                },
                {
                    name: "ProcessFromProcess",
                    path: "processus/:processId",
                    props: true,
                    component: () => import("@/views/quality/Process/Process")
                },
                {
                    name: "Ideas",
                    path: "ideas",
                    component: () => import("@/views/quality/Ideas")
                },

                {
                    name: "Dysfunctions",
                    path: "dysfunctions",
                    component: () => import("@/views/quality/Dysfunctions")
                },

            ]
        },
        {
            path: "/mint",
            redirect: "/mint/contacts",
            component: () => import("@/layouts/full-layout/Layout"),
            children: [
                {
                    name: "Contacts",
                    path: "contacts",
                    component: () => import("@/views/admin/Contacts")
                },
                {
                    name: "Team",
                    path: "team",
                    component: () => import("@/views/admin/Team")
                },
                {
                    name: "Tutorials",
                    path: "tutorials",
                    component: () => import("@/views/shared/Tutorials")
                },
                {
                    name: "Documents",
                    path: "documents",
                    component: () => import("@/views/shared/Documents")
                }
            ]
        },
        {
            path: "/team",
            redirect: "/mint/team",
            props: true,
            component: () => import("@/layouts/full-layout/Layout"),
            children: [
                {
                    name: "Membre",
                    path: ":id",
                    component: () => import("@/views/details/MemberDetails")
                }
            ]
        },
        {
            path: "/authentication",
            component: () => import("@/layouts/blank-layout/Blanklayout"),
            children: [
                {
                    name: "Login",
                    path: "login",
                    component: () => import("@/views/authentication/Login"),
                    meta: { guest: true }
                },
                {
                    name: "Error",
                    path: "error",
                    component: () => import("@/views/authentication/Error"),
                    meta: { guest: true }
                }
            ]
        },
        {
            name: "Project",
            path: "/project",
            props: true,
            component: () => import("@/layouts/full-layout/Layout"),
            children: [
                {
                    path: ":id",
                    name: "projectsDetailsPM",
                    component: () => import("@/views/details/ProjectDetails")
                },
                {
                    path: "support/:id",
                    name: "supportDetails",
                    component: () => import("@/views/details/SupportDetails")
                },
                {
                    path: "hosting/:id",
                    name: "hostingDetails",
                    component: () => import("@/views/details/HostingDetails")
                },
                {
                    path: "timesheet/:id",
                    name: "timesheetDetails",
                    component: () => import("@/views/details/TimesheetDetails")
                },
                {
                    path: "timebooks/:id",
                    name: "timebookDetails",
                    component: () => import("@/views/details/TimebookDetails")
                }
            ]
        },
        {
            path: "*",
            component: () => import("@/views/authentication/Error")
        }
    ]
});


router.beforeEach(async (to, from, next) => {
    const auth = getAuth()
    const storeUser = store.getters.getUser

    onAuthStateChanged(auth, (user) => {
        if (user && storeUser) {
            !to.meta.guest ? next() : next('/home')
        } else {
            !to.meta.guest ? next('/authentication/login') : next()
        }
    })
})

router.afterEach(() => { NProgress.done(); });

export default router;
