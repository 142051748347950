import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "@/store/store";
import Vuebar from "vuebar";
import "@/plugins/base";
import VueSkycons from "vue-skycons";
import { firestorePlugin } from 'vuefire'
import vGanttChart from 'v-gantt-chart'
import { db } from '@/firebase'


Vue.use(firestorePlugin)
Vue.use(vGanttChart)
Vue.use(VueSkycons, {
  color: "#f43152",
});

Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.prototype.$db = db

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
