import Vue from 'vue'
import Vuex from 'vuex'

const AUTH_SUCCESS = 'AUTH_SUCCESS'
const AUTH_LOGOUT = 'AUTH_LOGOUT'
const SET_TOKEN = 'SET_TOKEN'
const SET_ROLES = 'SET_ROLES'
const SET_TAGS = 'SET_TAGS'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: localStorage.getItem('mint-user') ? JSON.parse(localStorage.getItem('mint-user')) : '',
        Sidebar_drawer: null,
        Customizer_drawer: false,
        SidebarColor: 'white',
        SidebarBg: '',
        navbarColor: "#555",
    },
    mutations: {
        SET_SIDEBAR_DRAWER(state, payload) {
            state.Sidebar_drawer = payload
        },
        SET_CUSTOMIZER_DRAWER(state, payload) {
            state.Customizer_drawer = payload
        },
        SET_SIDEBAR_COLOR(state, payload) {
            state.SidebarColor = payload
        },
        [AUTH_SUCCESS]: (state, user) => {
            state.user = user
        },
        [AUTH_LOGOUT]: (state) => {
            state.user = false
        },
        [SET_TOKEN]: (state, token) => {
            state.user.tokens.access_token = token
        },
        [SET_ROLES]: (state, roles) => {
            state.user.roles = roles
        },
        [SET_TAGS]: (state, tags) => {
            state.user.tags = tags
        }
    },
    actions: {
        setUser: ({ commit }, payload) => {
            let userObject = {
                name: payload.googleUser.name,
                email: payload.googleUser.email,
                avatar: payload.googleUser.avatar,
                roles: payload.roles || ['COLLAB'],
                tags: payload.tags || [],
                id: payload.id,
                tokens: payload.googleUser.tokens
            }

            localStorage.setItem('mint-user', JSON.stringify(userObject))
            commit(AUTH_SUCCESS, userObject)
        },
        setToken: ({ commit }, token) => {
            const user = JSON.parse(localStorage.getItem('mint-user'))
            user.tokens.access_token = token

            localStorage.setItem('mint-user', JSON.stringify(user))
            commit(SET_TOKEN, token)

        },
        setRoles: ({ commit }, roles) => {
            const user = JSON.parse(localStorage.getItem('mint-user'))
            user.roles = roles

            localStorage.setItem('mint-user', JSON.stringify(user))
            commit(SET_ROLES, roles)
        },
        setTags: ({ commit }, tags) => {
            const user = JSON.parse(localStorage.getItem('mint-user'))
            user.tags = tags

            localStorage.setItem('mint-user', JSON.stringify(user))
            commit(SET_TAGS, tags)
        },
        signOut: ({ commit }) => {
            localStorage.setItem('mint-user', '')
            commit(AUTH_LOGOUT)
        },
    },
    getters: {
        getUser: state => {
            return state.user
        },
        getProjectsHeader: state => {
            return state.projectsHeaders
        },
    }
})