<template>
	<v-app id="materialpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
		<router-view />
	</v-app>
</template>

<script>
export default {
	name: "App",
};
</script>
<style lang="scss">
	.v-list-item__title {
		white-space: normal;
	}
</style>
