<template>
	<v-row class="page-breadcrumb">
		<v-col cols="12" sm="12" class="pa-0">
			<v-card class="px-2 custom-shaodow">
				<v-toolbar dense flat class="transparent pa-0">
					<!-- <v-toolbar-title class="pt-1">
						<span class="text--primary">{{ title }}</span>
					</v-toolbar-title> -->
					<!-- <v-toolbar-title class="toobar-extension transparent pb-1" slot="extension"> -->
					<v-breadcrumbs :items="breadcrumbs" class="pa-0">
						<template v-slot:divider v-if="breadcrumbs">
							<v-icon>mdi-chevron-right</v-icon>
						</template>
					</v-breadcrumbs>
					<!-- </v-toolbar-title> -->
				</v-toolbar>
			</v-card>
		</v-col>
	</v-row>
</template>
<script>
export default {
	name: "BaseBreadcrumb",
	props: {
		title: String,
		breadcrumbs: Array,
		icon: String,
	},
	data: () => ({}),
};
</script>
<style lang="scss">
.page-breadcrumb {
	margin: -25px -25px 27px -25px;
}
</style>